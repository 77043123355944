import React from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

import dataFromFile from './artists.json'
const data = dataFromFile

const makeDefaultState = () => ({
  sorted: [],
  page: 0,
  pageSize: data.length,
  expanded: {},
  resized: []
})

const columns = [
  {
    Header: document.documentElement.lang === 'nl' ? 'Naam' : 'Name',
    accessor: 'name',
    minWidth: 200,
    maxWidth: 600,
    Cell: e => (
      <a href={e.row.link} target='_blank'>
        {' '}
        {e.value}{' '}
      </a>
    )
  },
  {
    Header: document.documentElement.lang === 'nl' ? 'Land' : 'Country',
    accessor: document.documentElement.lang === 'nl' ? 'country' : 'countryen',
    minWidth: 140,
    maxWidth: 600,
    Cell: props => <span className='number'>{props.value}</span>
  },
  {
    id: 'City',
    minWidth: 140,
    maxWidth: 600,
    Header: document.documentElement.lang === 'nl' ? 'Stad' : 'City',
    accessor: document.documentElement.lang === 'nl' ? 'city' : 'cityen'
  },
  {
    id: 'link',
    Header: 'Link',
    show: false,
    accessor: 'link'
  }
]

class ArtistsTable extends React.Component {
  constructor () {
    super()
    this.state = makeDefaultState()
  }

  render () {
    return (
      <div
        style={{
          // minHeight: '20vh'
        }}
      >
        <ReactTable
          data={data}
          columns={columns}
          // Controlled props
          //sorted={this.state.sorted}
          page={this.state.page}
          pageSize={this.props.lenght}
          expanded={this.state.expanded}
          resized={this.state.resized}
          filtered={this.props.filtered}
          columns={columns}
          showPagination={false}
          resizable={false}
          style={{
            maxHeight: '66vh'
          }}
        />
        <br />
      </div>
    )
  }
}

export { ArtistsTable }
