import isMobile from 'ismobilejs'

function colors () {
  // Get the device pixel ratio, falling back to 1.
  let dpr = window.devicePixelRatio || 1
  if (isMobile.any) dpr = 1

  const stripsCount = 80
  let imageSize = window.innerWidth / 4
  let stripWidth = window.innerWidth / 40 * dpr
  let stripHeight = window.innerHeight / 3 * dpr
  const maximumspeed = 2

  let canvas = document.getElementById('canvas')
  let ctx = canvas.getContext('2d', {
    alpha: true
  })

  let width = window.innerWidth * dpr
  let height = window.innerHeight * dpr
  ctx.canvas.width = width
  ctx.canvas.height = height
  ctx.fillStyle = '#E5E4CA'
  ctx.fillRect(0, 0, width, height)

  const m_canvas = document.createElement('canvas')
  m_canvas.width = width
  m_canvas.height = height
  var m_ctx = m_canvas.getContext('2d')

  let imagePosition = {
    x: ctx.canvas.width - imageSize / 2 - window.innerWidth * 0.02 * dpr,
    y: ctx.canvas.height - imageSize / 2 - window.innerWidth * 0.02 * dpr
  }

  window.addEventListener('resize', () => {
    width = window.innerWidth * dpr
    height = window.innerHeight * dpr
    imageSize = window.innerWidth / 4
    stripWidth = window.innerWidth / 40 * dpr
    stripHeight = window.innerHeight / 3 * dpr
    ctx.canvas.width = width
    ctx.canvas.height = height
    m_canvas.width = width
    m_canvas.height = height
    imagePosition = {
      x: ctx.canvas.width - imageSize / 2 - window.innerWidth * 0.02 * dpr,
      y: ctx.canvas.height - imageSize / 2 - window.innerWidth * 0.02 * dpr
    }
  })

  let cursor = {
    x: 0,
    y: 0
  }

  let samples = new Array(stripsCount)
  samples.fill({
    color: '#E5E4CA',
    width: 10
  })

  let rotation = 0
  let speed = 0

  let myImage = new Image(400, 400)
  myImage.src = 'assets/img/wheel_1.png'

  let imageiIndex = 0 

  canvas.addEventListener('dblclick', () => {
    imageiIndex++
    myImage.src = 'assets/img/wheel_' + (imageiIndex % 8 + 1) + '.png'
  })

  function step (timestamp) {
    m_ctx.beginPath()

    // Draw background
    let row = 0
    let rectX = 0
    let rectY = 0
    let overrun = 0
    for (let i = 0; i < stripsCount; i++) {
      m_ctx.fillStyle = samples[i].color
      rectY = stripHeight * row
      m_ctx.fillRect(rectX, rectY, samples[i].width, stripHeight)
      rectX += samples[i].width
      if (rectX >= width) {
        overrun = rectX - width
        row++
        rectX = 0
        rectY = stripHeight * row
        m_ctx.fillRect(rectX, rectY, overrun, stripHeight)
        rectX += overrun
      }
    }

    m_ctx.closePath()
    // ctx.drawImage(m_canvas, 0, 0)

    // Draw vynil
    m_ctx.save()
    m_ctx.translate(imagePosition.x, imagePosition.y)
    rotation += Math.max(0, speed)
    m_ctx.rotate(rotation / 100)
    m_ctx.translate(-imagePosition.x - imageSize / 2, -imagePosition.y - imageSize / 2)
    m_ctx.drawImage(myImage, imagePosition.x, imagePosition.y, imageSize, imageSize)
    m_ctx.restore()

    ctx.drawImage(m_canvas, 0, 0)

    // Speed control
    if (distance(cursor.x * dpr, cursor.y * dpr, imagePosition.x, imagePosition.y) < imageSize / 2) {
      speed = Math.min(maximumspeed, speed + 0.2)
    } else {
      speed = Math.max(0, speed - 0.1)
    }

    // Read data from image
    let sample = {
      color: '#E5E4CA',
      width: Math.ceil(speed * stripWidth)
    }
    if (distance(cursor.x * dpr, cursor.y * dpr, imagePosition.x, imagePosition.y) < imageSize / 2) {
      let imgData = m_ctx.getImageData(cursor.x * dpr, cursor.y * dpr, 1, 1)
      let color = `rgb(${imgData.data[0]},${imgData.data[1]},${imgData.data[2]})`
      sample = {
        color: color,
        width: Math.ceil(speed * stripWidth)
      }
    }
    if (speed > 0) {
      samples.unshift(sample)
      samples.pop()
    }
    window.requestAnimationFrame(step)
  }

  window.requestAnimationFrame(step)

  document.addEventListener('mousemove', event => {
    cursor.x = event.clientX
    cursor.y = event.clientY + window.scrollY
  })

  document.addEventListener('touchstart', event => {
    if (event.touches) {
      cursor.x = event.touches[0].clientX
      cursor.y = event.touches[0].clientY
    } else {
      cursor.x = event.clientX
      cursor.y = event.clientY + window.scrollY
    }
  }, false)

  document.addEventListener('touchend', event => {
    cursor.x = 0
    cursor.y = 0
  }, false)

  // Helper functions
  function distance (x1, y1, x2, y2) {
    let a = x1 - x2
    let b = y1 - y2
    return Math.sqrt(a * a + b * b)
  }
}

export {
  colors
}
